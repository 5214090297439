import React from 'react'
import { Icons } from '../../../../Shared/Ant'
import { Tooltip, Typography, Space } from 'antd'
import './styles.modules.scss'
import OrderHelper from '../../../../../helpers/OrderHelper'
import { Order } from '../../../../../models/order/order'
import { DocsToNotarySent } from '../../../../../models/order/docsToNotarySent'

export const DocsColumn = () => {
  const tooltipTitle = (docsToNotarySent: DocsToNotarySent) => {
    switch (docsToNotarySent) {
      case DocsToNotarySent.AtClosing:
        return 'Docs at closing'
      case DocsToNotarySent.NotaryPickedUpDocs:
        return 'Notary picked up docs'
      case DocsToNotarySent.Overnighted:
        return 'Docs overnighted'
      case DocsToNotarySent.EmailedToNotary:
        return 'Docs emailed to notary'
      default:
        return 'Docs at closing'
    }
  }

  const attachmentTextClass = (order: Order) => {
    if (!order.docsToNotarySent) {
      return 'color-ant-blue-4'
    } else if (!OrderHelper.isDocsShouldBeDownloaded(order) || order.notaryDownloadedAllDocsScoped) {
      return 'color-ant-green-4'
    }
    return 'color-ant-orange-4'
  }

  return {
    title: 'Docs',
    dataIndex: 'docs',
    className: 'table-column docs-column',
    render: (text: string, order: Order) => {
      return (
        <Space>
          {OrderHelper.isDocsStatusDelivered(order) && (
            <Tooltip title={tooltipTitle(order.docsToNotarySent)}>
              <Icons.Upload className={OrderHelper.isOpen(order) ? 'upload-icon color-ant-green-4' : 'upload-icon'} />
            </Tooltip>
          )}
          {order.docsAttached && order.attachmentsUploadedByAdminOrClientSize > 0 && (
            <Tooltip title={order.docsTitle}>
              <Typography.Text
                className={`docs-number ${OrderHelper.isCompleted(order) ? '' : attachmentTextClass(order)}`}
              >
                {order.attachmentsUploadedByAdminOrClientSize}
              </Typography.Text>
            </Tooltip>
          )}
        </Space>
      )
    }
  }
}
