import React, { FC, ReactElement, useEffect, useState } from 'react'
import { Modal, Icons } from '../../Shared/Ant'
import { Image, Row, Col } from 'antd'
import { ForwardNotificationProps, Comment } from './types'
import moment from 'moment-timezone'
import { Requests } from '../../../api'
import { get } from 'lodash'
import { createResource } from '../../../actions/createResource'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { userTimeZone } from '../../../utils/userTimeZone'

export const ForwardNotification: FC<ForwardNotificationProps> = ({
  comment,
  notification,
  changeReadStatus,
  handleTooltipText
}): ReactElement => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [commenterType, setCommenterType] = useState('')
  const [forwardTo, setForwardTo] = useState('')
  const [forwardToName, setForwardToName] = useState('')
  const [showForward, setShowForward] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const { path: commentPath, type: commentType } = Requests.orderComment({
    orderId: notification.orderId,
    id: notification.notifiableId
  })
  const formattedNotificationDate = moment(get(comment, 'createdAt', ''))
    .tz(userTimeZone())
    .format('MMMM DD, YYYY [at] h:mma z')
  const dispatch = useAppDispatch()

  const showModal = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    setIsModalVisible(true)
  }

  const handleOk = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation()
    setConfirmLoading(true)

    dispatch<any>(
      createResource({
        path: `${commentPath}/forward`,
        type: commentType,
        params: {}
      })
    ).then(() => {
      setShowForward(false)
      setConfirmLoading(false)
      setIsModalVisible(false)
      handleTooltipText(forwardTo, comment.forwardedBy)
      changeReadStatus(null, notification, true)
    })
  }

  const handleCancel = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation()

    setIsModalVisible(false)
  }

  const handleForwardTo = (comment: Comment) => {
    if (comment.commenterType === 'Notary') {
      setCommenterType('notary')
      setForwardTo('client')
      setForwardToName(comment.clientName)
    } else if (comment.commenterType === 'Client::TeamMember') {
      setCommenterType('client')
      setForwardTo('notary')
      setForwardToName(comment.notaryName)
    }
  }

  useEffect(() => {
    handleForwardTo(comment)
    setShowForward(comment.forwardable)
  }, [])

  return (
    <>
      {showForward && (
        <div className="forward-notification" data-test="forward-notification" onClick={(e) => showModal(e)}>
          <div className="forward-notification-button">
            <Icons.Share className="notification-read-icon" />
            Forward
          </div>

          <Modal
            okText="Forward"
            width={820}
            visible={isModalVisible}
            onOk={(e: React.MouseEvent<Element, MouseEvent>) => handleOk(e)}
            confirmLoading={confirmLoading}
            onCancel={(e: React.MouseEvent<Element, MouseEvent>) => handleCancel(e)}
            className="forward-notification-modal"
          >
            <div className="forward-notification-content">
              <Row justify="center">
                <Col span={18}>
                  <p className="forward-notification-instruction">
                    Forward this {commenterType} comment to the {forwardTo} <strong>{forwardToName}</strong>?
                  </p>
                </Col>
              </Row>
              <Row>
                <Col span={2}>
                  {comment.commenterAvatar ? (
                    <Image width={50} src={comment.commenterAvatar} />
                  ) : (
                    <div>{comment.commenterInitials}</div>
                  )}
                </Col>
                <Col span={20}>
                  <div className="forward-notification-text-arrow" />
                  <div className="forward-notification-text">
                    <div className="forward-notification-data">
                      <span className="forward-notification-commenter-name">{comment.commenterName}</span>
                      <span className="forward-notification-date">{formattedNotificationDate}</span>
                    </div>
                    {comment.text}
                  </div>
                </Col>
              </Row>
            </div>
          </Modal>
        </div>
      )}
    </>
  )
}
