import React, { FC, ReactElement, useEffect } from 'react'
import { Icons } from '../../Shared/Ant'
import { Button, Checkbox, Pagination, Row, Col } from 'antd'
import { OrderNotification } from './OrderNotification'
import { NotificationsSkeleton } from './Skeleton/NotificationsSkeleton'
import { SortNotification } from './SortNotification'
import { getQueryParamsState, setQueryParamsState } from '../../../utils/queryParams'
import { Snapdocs } from '../../../hooks/api'
import { Requests } from '../../../api'
import { get } from 'lodash'
import { MarkAsReadBulk } from './MarkAsReadBulk/MarkAsReadBulk'
import { pusherProvider } from '../../../utils/pusherProvider'
import { ChannelEvents } from '../../../models/event/channelEvents'

export const OrderNotifications: FC<{
  isRootCompany: boolean
}> = ({ isRootCompany }): ReactElement => {
  const params = getQueryParamsState()
  const localstorage = window.localStorage
  const owner = params.owner || 'all'
  const page = params.page || 1
  const status = params.status || localstorage.getItem('status') || 'unread'
  const sort = params.sort || localstorage.getItem('sort') || 'received_at_desc'
  const { path: newOrderPath } = Requests.order({ id: 'new' })
  const { request: notificationRequest, response: notifications } = Snapdocs.useResources(
    Requests.notifications({ owner, status, sort, page })
  )
  const { response: unreadCountResponse } = Snapdocs.useResources(Requests.unreadNotificationsCount({ owner }))
  const { response: appConfig } = Snapdocs.useResources(Requests.appConfig())

  const renderNotifications = get(notificationRequest, 'ids', []).map((id: number) => (
    <OrderNotification key={id} notification={notifications[id]} status={status} />
  ))

  const changeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      params.status = 'unread'
    } else {
      params.status = 'all'
    }
    localstorage.setItem('status', params.status)
    params.page = 1

    setQueryParamsState(params)
    Snapdocs.dispatchReset('notifications')
  }

  const changeSort = (value: string) => {
    params.page = 1
    params.sort = value
    localstorage.setItem('sort', params.sort)

    setQueryParamsState(params)
    Snapdocs.dispatchReset('notifications')
  }

  const changePage = (page: number) => {
    params.page = page

    setQueryParamsState(params)
    Snapdocs.dispatchReset('notifications')
  }

  const documentTitle = () => document.title.split(') ').pop() || ''

  useEffect(() => {
    const unreadNotificationsCount = get(unreadCountResponse, 'unreadCount', 0)
    document.title = unreadNotificationsCount > 0 ? `(${unreadNotificationsCount}) ${documentTitle()}` : documentTitle()
  }, [unreadCountResponse])

  useEffect(() => {
    if (!appConfig) return

    const pusher = pusherProvider(appConfig.pusherAppKey, appConfig.pusherAppCluster)

    const channels = appConfig.companyChannels.map((channelName: string) => pusher.subscribe(channelName))

    for (const channel of channels) {
      channel.bind(ChannelEvents.OrderNotificationAdded, () => {
        Snapdocs.dispatchReset('notifications')
        Snapdocs.dispatchReset('unreadNotificationsCount')
      })
    }

    return () => {
      for (const channel of channels) {
        channel.unbind(ChannelEvents.OrderNotificationAdded)
      }
    }
  }, [appConfig])

  if (Requests.isLoading(notificationRequest)) {
    return <NotificationsSkeleton />
  }

  return (
    <div className="content" style={{ marginRight: 13 }}>
      <div className="order-notifications" data-testid="order-notifications">
        <Row className="header" align="middle">
          <Col span="18">
            <SortNotification selectedValue={sort} handleChange={changeSort} />
            <Checkbox onChange={changeStatus} checked={status === 'unread'} data-test="only-show-unread-notifications">
              Only show unread notifications ({get(unreadCountResponse, 'unreadCount', 0)})
            </Checkbox>
            <MarkAsReadBulk unreadCount={get(unreadCountResponse, 'unreadCount', 0)} />
          </Col>
          {!isRootCompany && (
            <Col span="6">
              <div className="text-right">
                <Button type="primary" href={newOrderPath}>
                  + Create order
                </Button>
              </div>
            </Col>
          )}
        </Row>
        {Object.keys(notifications).length > 0 ? (
          renderNotifications
        ) : (
          <Row>
            <Col span="24" className="no-new-notifications text-center">
              <Icons.Bell className="icon" />
              <div className="text">No notifications at this time</div>
            </Col>
          </Row>
        )}
        <Row className="footer">
          <Col span="12" offset="6" className="text-center">
            <Pagination
              showQuickJumper
              showSizeChanger={false}
              hideOnSinglePage={true}
              current={notificationRequest.meta.currentPage}
              pageSize={notificationRequest.meta.perPage}
              total={notificationRequest.meta.totalEntries}
              onChange={changePage}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}
