import React, { FC, ReactElement } from 'react'
import { Space, Select, Typography, Form, Tooltip } from 'antd'
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList'
import { RonFormProps, RonFormValues, RestrictionItem } from '../types'
import { DeleteOutlined } from '@ant-design/icons'
import { RON } from '../constants/RON'
import './styles.modules.scss'

export const RonForm: FC<RonFormProps> = ({
  states,
  disabledStates,
  restrictedStates,
  providers,
  selectedProviders,
  selectedRestrictions
}): ReactElement => {
  const [form] = Form.useForm()

  const handlePropertyStateChange = (value: string, fieldName: number) => {
    if (value) {
      form.setFieldValue(['restrictions', fieldName, 'eligibleStates'], [value])
      document.dispatchEvent(new CustomEvent('RonFormValuesChanged', { detail: form.getFieldsValue() }))
    }
  }

  const stateOptions = (certificationState: boolean, currentFieldName: number) => {
    const formValues = form.getFieldsValue() as RonFormValues
    const selectedPropertyStates = formValues.restrictions
      ? formValues.restrictions
          .filter(
            (restriction: RestrictionItem, index: number) =>
              restriction && restriction.propertyState && index !== currentFieldName
          )
          .map((restriction: RestrictionItem) => restriction.propertyState)
      : []

    return Object.keys(states)
      .filter((stateCode) => certificationState || !selectedPropertyStates.includes(stateCode))
      .map((stateCode) => {
        const tooltipTitle =
          restrictedStates.includes(stateCode) && !certificationState
            ? `${RON.FORM.RESTRICTED_TOOLTIP}`
            : `${RON.FORM.DISABLED_TOOLTIP}`

        return (
          <Select.Option
            key={stateCode}
            value={stateCode}
            disabled={
              disabledStates.includes(stateCode) || (restrictedStates.includes(stateCode) && !certificationState)
            }
          >
            {disabledStates.includes(stateCode) || (restrictedStates.includes(stateCode) && !certificationState) ? (
              <Tooltip title={tooltipTitle}>{states[stateCode]}</Tooltip>
            ) : (
              states[stateCode]
            )}
          </Select.Option>
        )
      })
  }

  return (
    <Form
      form={form}
      name="ron-settings-form"
      className="ron-settings-form"
      initialValues={{ providers: selectedProviders, restrictions: selectedRestrictions }}
      onValuesChange={(_changedValues: Partial<RonFormValues>, allValues: RonFormValues) => {
        document.dispatchEvent(new CustomEvent('RonFormValuesChanged', { detail: allValues }))
      }}
    >
      <Space direction="vertical">
        <Space direction="vertical">
          <Typography.Text strong>{RON.FORM.PROVIDERS_LABEL}</Typography.Text>
          <Form.Item name="providers">
            <Select
              mode="multiple"
              style={{ width: 250 }}
              placeholder={RON.FORM.PROVIDERS_PLACEHOLDER}
              maxTagCount={1}
              showArrow={true}
              options={providers.map((provider) => ({ label: provider[0], value: provider[1] }))}
            />
          </Form.Item>
        </Space>
        <Space direction="vertical">
          <Typography.Text strong>{RON.NOTARY_STATE_RESTRICTIONS.TITLE}</Typography.Text>
          <Typography.Text type="secondary">{RON.NOTARY_STATE_RESTRICTIONS.DESCRIPTION}</Typography.Text>
        </Space>
        <Form.List name="restrictions">
          {(fields: FormListFieldData[], { add, remove }: FormListOperation) => {
            return (
              <>
                {fields.map((field: FormListFieldData) => (
                  <Space key={field.key} size="middle">
                    <Space direction="vertical">
                      <Typography.Text strong>{RON.FORM.PROPERTY_STATE_LABEL}</Typography.Text>
                      <Form.Item {...field} key={'propertyState' + field.key} name={[field.name, 'propertyState']}>
                        <Select
                          style={{ width: 250 }}
                          placeholder={RON.FORM.PROPERTY_STATE_PLACEHOLDER}
                          showArrow={true}
                          onChange={(value: string) => handlePropertyStateChange(value, field.name)}
                        >
                          {stateOptions(false, field.name)}
                        </Select>
                      </Form.Item>
                    </Space>
                    <Space direction="vertical">
                      <Typography.Text strong>{RON.FORM.NOTARY_CERTIFICATION_STATES_LABEL}</Typography.Text>
                      <Form.Item {...field} key={'eligibleStates' + field.key} name={[field.name, 'eligibleStates']}>
                        <Select
                          mode="multiple"
                          style={{ width: 250 }}
                          placeholder={RON.FORM.NOTARY_CERTIFICATION_STATES_PLACEHOLDER}
                          maxTagCount={1}
                          showArrow={true}
                          showSearch={true}
                        >
                          {stateOptions(true, field.name)}
                        </Select>
                      </Form.Item>
                    </Space>
                    <DeleteOutlined
                      style={{ fontSize: 18, marginTop: 6 }}
                      onClick={() => {
                        remove(field.name)
                        document.dispatchEvent(
                          new CustomEvent('RonFormValuesChanged', { detail: form.getFieldsValue() })
                        )
                      }}
                    />
                  </Space>
                ))}
                <Form.Item>
                  <Typography.Link onClick={() => add()}>
                    {RON.FORM.ADD_NOTARY_STATE_RESTRICTION_BUTTON}
                  </Typography.Link>
                </Form.Item>
              </>
            )
          }}
        </Form.List>
      </Space>
    </Form>
  )
}
