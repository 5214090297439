import React, { FC, ReactElement, useState, useEffect } from 'react'
import { Icons } from '../../Shared/Ant'
import { Row, Col, Tooltip } from 'antd'
import { Snapdocs } from '../../../hooks/api'
import { Requests } from '../../../api'
import { AppointmentFullTime } from './AppointmentFullTime'
import { NotificationIcon } from './NotificationIcon'
import { NotificationCreatedAt } from './NotificationCreatedAt'
import { NotificationReadBy } from './NotificationReadBy'
import { MarkAsRead } from './MarkAsRead'
import { ForwardNotification } from './ForwardNotification'
import { Comment, CommentNotificationProps } from './types'
import moment from 'moment-timezone'
import { userTimeZone } from '../../../utils/userTimeZone'
import { ClientAndCompanyName } from './ClientAndCompanyName'

export const CommentNotification: FC<CommentNotificationProps> = ({ notification, changeReadStatus }): ReactElement => {
  const [renderForwardButton, setRenderForwardButton] = useState(false)
  const [forwardTo, setForwardTo] = useState('')
  const [showForward, setShowForward] = useState(false)
  const [tooltipText, setTooltipText] = useState('')
  const { request: orderCommentRequest, response: comment } = Snapdocs.useResources(
    Requests.orderComment({ orderId: notification.orderId, id: notification.notifiableId })
  )

  const formattedNotificationDate = () => {
    return moment(comment.createdAt).tz(userTimeZone()).format('MMMM DD, YYYY [at] h:mma z')
  }

  const handleForwardFields = (comment: Comment) => {
    if (comment.commenterType === 'Notary') {
      setForwardTo('Client')
      handleTooltipText('Client', comment.forwardedBy || '')
    } else if (comment.commenterType === 'Client::TeamMember') {
      setForwardTo('Notary')
      handleTooltipText('Notary', comment.forwardedBy || '')
    }
  }

  const handleTooltipText = (forwardTo: string, forwardedBy: string) => {
    setTooltipText('Forwarded to ' + forwardTo + ' on ' + formattedNotificationDate() + ' by ' + forwardedBy)
  }

  useEffect(() => {
    if (Requests.hasLoaded(orderCommentRequest)) {
      handleForwardFields(comment as Comment)
      setShowForward(comment.forwardable)
    }
  }, [orderCommentRequest])

  return (
    <div
      className="order-notification"
      onMouseEnter={() => setRenderForwardButton(true)}
      data-test="order-notification"
    >
      <Row
        className={`${!notification.readBy ? 'not-read' : 'read'}`}
        data-test={`${notification.readBy ? 'order-notification-read' : 'order-notification-not-read'}`}
        wrap={false}
      >
        <Col flex="250px">
          <NotificationIcon classification={notification.classification} />
          <AppointmentFullTime date={notification.appointmentDate} time={notification.appointmentTime} />
        </Col>
        <Col flex="auto">
          <div>
            <span className="subject">{notification.subject}</span>
            <span className="ellipse" />
            <span>
              {notification.signer} (#{notification.orderId})
            </span>
          </div>
          <div className="notification-preview" data-test="notification-preview">
            {comment?.hasBeenForwarded && (
              <Tooltip title={tooltipText}>
                <div className="forwarded-indicator" data-test="forwarded-indicator">
                  <Icons.Share className="forwarded-icon" style={{ fontSize: '14px' }} />
                  {forwardTo}
                </div>
              </Tooltip>
            )}
            <div className="notification-text" data-test="notification-text">
              {notification.text}
            </div>
          </div>
        </Col>
        <ClientAndCompanyName notification={notification} />
        <Col flex="250px">
          <div className="text-right">
            {showForward && renderForwardButton && (
              <ForwardNotification
                key={'forward-notification-' + notification.id}
                notification={notification}
                comment={comment}
                changeReadStatus={changeReadStatus}
                handleTooltipText={handleTooltipText}
              />
            )}
            <MarkAsRead key={notification.id} notification={notification} changeReadStatus={changeReadStatus} />
            <div className="notification-created-at">
              <NotificationCreatedAt dateTime={notification.createdAt} />
            </div>
            {notification.readBy && notification.readAt && (
              <NotificationReadBy readBy={notification.readBy} readAt={notification.readAt} />
            )}
          </div>
        </Col>
      </Row>
    </div>
  )
}
