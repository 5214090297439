import React, { FC, ReactElement } from 'react'
import { Col, Row, Skeleton, Space } from 'antd'
import { NotificationSkeleton } from './NotificationSkeleton'

export const NotificationsSkeleton: FC<{}> = (): ReactElement => {
  const renderNotificationsSkeleton = [...Array(10).keys()].map((id) => <NotificationSkeleton key={id} />)

  return (
    <div className="content" style={{ marginRight: 13 }}>
      <div className="order-notifications" data-test="order-notifications">
        <Row className="header">
          <Col span="12">
            <Space size={24}>
              <Skeleton.Input style={{ width: 266, height: 16 }} active />
              <Skeleton.Input style={{ width: 280, height: 16 }} active />
            </Space>
          </Col>
          <Col span="12" className="text-right">
            <Skeleton.Button style={{ width: 120, height: 32 }} active shape="square" />
          </Col>
        </Row>
        {renderNotificationsSkeleton}
      </div>
    </div>
  )
}
