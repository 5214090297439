import React, { FC, ReactElement, useState, useEffect } from 'react'
import { Input, Button } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Rule, RuleConditionFields, JsonLogicRule } from './types'
import { RuleLogicEditor } from './RuleLogicEditor'
import { IneligibilityReason } from './IneligibilityReason'
import './styles.modules.scss'

interface RuleEditorProps {
  rule?: Rule
  onChange: (rule: Rule) => Promise<boolean>
  onDeleteRule: (ruleId: number) => void
  supportedRuleConditionFields: RuleConditionFields[]
  isEditMode: boolean
}

export const RuleEditor: FC<RuleEditorProps> = ({
  rule,
  onChange,
  onDeleteRule,
  supportedRuleConditionFields,
  isEditMode
}): ReactElement => {
  // Store original rule state for cancel functionality
  const [originalRule, setOriginalRule] = useState<Rule | undefined>(rule)
  const [editMode, setEditMode] = useState(isEditMode)
  const [ruleName, setRuleName] = useState<string>(rule?.name || '')
  const [validationError, setValidationError] = useState<string | undefined>(undefined)
  const [ruleLogic, setRuleLogic] = useState<JsonLogicRule>(() => {
    // Initialize with rule.logic if available, otherwise use default
    if (rule && rule.logic) {
      // Handle case where rule.logic might be a string
      if (typeof rule.logic === 'string') {
        try {
          return JSON.parse(rule.logic)
        } catch (error) {
          console.error('Error parsing rule logic json string:', error)
          return { '==': [{ var: '' }, ''] }
        }
      }

      return rule.logic
    }
    return { '==': [{ var: '' }, ''] }
  })
  const [ineligibilityReason, setIneligibilityReason] = useState<string>(rule?.ineligibilityReason || '')
  const [isReasonCustomized, setIsReasonCustomized] = useState(rule?.isCustomIneligibilityReason || false)
  const [customIneligibilityReason, setCustomIneligibilityReason] = useState<string>(
    isReasonCustomized ? ineligibilityReason : ''
  )

  useEffect(() => {
    if (rule) {
      setRuleName(rule.name)

      // Handle case where rule.logic might be a string
      let parsedLogic = rule.logic
      if (typeof rule.logic === 'string') {
        try {
          parsedLogic = JSON.parse(rule.logic)
        } catch (error) {
          console.error('Error parsing rule.logic string in useEffect:', error)
          parsedLogic = { '==': [{ var: '' }, ''] }
        }
      }

      // Only update ruleLogic if it's different from current state
      if (JSON.stringify(parsedLogic) !== JSON.stringify(ruleLogic)) {
        setRuleLogic(parsedLogic)
      }

      setIneligibilityReason(rule.ineligibilityReason || '')
      setIsReasonCustomized(rule.isCustomIneligibilityReason || false)
    }
  }, [rule])

  const onChangeRuleName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRuleName(e.target.value)
  }

  const handleRuleLogicChange = (updatedRuleLogic: JsonLogicRule, error?: string) => {
    setRuleLogic(updatedRuleLogic)
    setValidationError(error)
  }

  const isValidCustomIneligibilityReason = () => {
    if (customIneligibilityReason && customIneligibilityReason.length > 0) {
      return true
    }

    if (customIneligibilityReason.length > 255) {
      return false
    }

    return false
  }

  const onSaveRule = async () => {
    // Don't allow saving if there are validation errors
    if (validationError) {
      return
    }
    const defaultIneligibilityReason =
      ineligibilityReason.length === 0 ? `${ruleName} is not eligible` : ineligibilityReason
    const isValidCustomReason = isValidCustomIneligibilityReason()
    const finalIneligibilityReason =
      isReasonCustomized && isValidCustomReason ? customIneligibilityReason : defaultIneligibilityReason

    const finalRule = {
      ...(rule || {}),
      id: rule ? rule.id : Date.now(),
      name: ruleName,
      logic: ruleLogic,
      ineligibilityReason: finalIneligibilityReason,
      isCustomIneligibilityReason: isValidCustomReason && isReasonCustomized
    }

    // Call onChange and wait for the result
    const success = await onChange(finalRule)

    // Only update local state and exit edit mode if save was successful
    if (success) {
      setIneligibilityReason(finalIneligibilityReason)
      setEditMode(false)
    }
  }

  const onClickEdit = () => {
    // Store the current rule state before entering edit mode
    setOriginalRule({
      id: rule?.id || 0,
      name: rule?.name || '',
      logic: rule?.logic || { '==': [{ var: '' }, ''] },
      ineligibilityReason: rule?.ineligibilityReason || '',
      isCustomIneligibilityReason: rule?.isCustomIneligibilityReason || false
    })
    setEditMode(true)
  }

  const onClickCancelEdit = () => {
    // Restore original values when canceling
    if (originalRule) {
      setRuleName(originalRule.name)

      // Handle case where originalRule.logic might be a string
      let parsedLogic = originalRule.logic
      if (typeof originalRule.logic === 'string') {
        try {
          parsedLogic = JSON.parse(originalRule.logic)
        } catch (error) {
          console.error('Error parsing originalRule.logic string in onClickCancelEdit:', error)
          parsedLogic = { '==': [{ var: '' }, ''] }
        }
      }

      setRuleLogic(parsedLogic)
      setIneligibilityReason(originalRule.ineligibilityReason)
      setIsReasonCustomized(originalRule.isCustomIneligibilityReason)

      if (originalRule.isCustomIneligibilityReason) {
        setCustomIneligibilityReason(originalRule.ineligibilityReason)
      } else {
        setCustomIneligibilityReason('')
      }

      setValidationError(undefined)
    }

    setEditMode(false)
  }

  return (
    <>
      <div className="ruleEditorHeaderWrapper">
        <div>
          {!editMode && <div className="ruleNameText">{ruleName}</div>}
          {editMode && (
            <Input placeholder="Rule name" value={ruleName} onChange={onChangeRuleName} className="ruleNameEditor" />
          )}
        </div>
        <div className="ruleEditorActionButtons">
          {!editMode && (
            <Button
              type="default"
              icon={<DeleteOutlined />}
              onClick={() => onDeleteRule(rule?.id || 0)}
              className="deleteButton"
            />
          )}
          {!editMode && <Button type="default" icon={<EditOutlined />} onClick={onClickEdit} className="editButton" />}
          {editMode && (
            <Button type="primary" onClick={onSaveRule} className="saveButton" disabled={!!validationError}>
              Save
            </Button>
          )}
          {editMode && (
            <Button type="text" onClick={onClickCancelEdit} className="saveButton">
              Cancel
            </Button>
          )}
        </div>
      </div>

      <RuleLogicEditor
        ruleLogic={ruleLogic}
        supportedRuleConditionFields={supportedRuleConditionFields}
        editMode={editMode}
        onRuleChange={handleRuleLogicChange}
        setRuleName={setRuleName}
      />

      <IneligibilityReason
        ineligibilityReason={ineligibilityReason}
        customIneligibilityReason={customIneligibilityReason}
        isCustomized={isReasonCustomized || false}
        setCustomIneligibilityReason={setCustomIneligibilityReason}
        setEditMode={setEditMode}
        editMode={editMode}
        setIsReasonCustomized={setIsReasonCustomized}
      />
    </>
  )
}
