import { Row, Col, Form, Input, Select, Button, Checkbox } from 'antd'
import React, { FC, ReactElement } from 'react'
import { CompanyOptions, EmailSettingsOptions, RoutingFormParams } from './types'
import { validateEmails } from '../../../utils/antFormValidations/validateEmails'
import { get } from 'lodash'
import { useConfirmBrowserExit } from '../../../hooks/useConfirmBrowserExit'
import { FORM_RULES } from '../../../utils/formRules'

const gridLayout = {
  xs: 24,
  sm: 12
}

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 }
}

export const RoutingForm: FC<RoutingFormParams> = ({
  company,
  emailSettings,
  companyOptions,
  emailSettingsOptions,
  handleFinish,
  isLoading
}): ReactElement => {
  const confirmBrowserExit = useConfirmBrowserExit()

  const renderOptions = (options: CompanyOptions | EmailSettingsOptions, key: string) => {
    return get(options, key).map((option: string[], index: number) => (
      <Select.Option key={index} value={option[1]}>
        {option[0]}
      </Select.Option>
    ))
  }

  return (
    <Form
      {...formLayout}
      initialValues={{
        defaultCompanyEmail: company.defaultCompanyEmail,
        postClosingEmail: company.postClosingEmail,
        mailBcc: company.mailBcc,
        observerNotificationLevel: company.observerNotificationLevel,
        invoiceMail: company.invoiceMail,
        displayNotaryInfoOnClientInvoice: company.displayNotaryInfoOnClientInvoice,
        displayOrderAddressOnClientInvoice: company.displayOrderAddressOnClientInvoice,
        accountingEmail: company.accountingEmail,
        orderClientOrNotaryReplyTo: emailSettings.orderClientOrNotaryReplyTo,
        emailsSentToUsersReplyTo: emailSettings.emailsSentToUsersReplyTo,
        orderSendClientInvoiceFrom: emailSettings.orderSendClientInvoiceFrom
      }}
      onFieldsChange={() => {
        confirmBrowserExit.enable()
      }}
      onFinish={(values: any) => {
        handleFinish(values)
        confirmBrowserExit.disable()
      }}
    >
      <Row gutter={24}>
        <Col {...gridLayout}>
          <Form.Item
            label="General company email address"
            name="defaultCompanyEmail"
            className="routing-form-item"
            rules={FORM_RULES.standardCommaSeparatedEmails}
          >
            <Input />
          </Form.Item>
          {company.postClosingTools ||
            (company.cqcTitleEnabled && (
              <Form.Item
                label="Post-closing email address (optional)"
                name="postClosingEmail"
                className="routing-form-item"
                rules={[validateEmails]}
              >
                <Input />
              </Form.Item>
            ))}
          {!company.postClosingTools && !company.cqcTitleEnabled && (
            <Form.Item
              label="Participants receive an email when:"
              name="observerNotificationLevel"
              className="routing-form-item"
            >
              <Select>{renderOptions(companyOptions, 'observerNotificationLevel')}</Select>
            </Form.Item>
          )}
        </Col>
        <Col {...gridLayout}>
          <Form.Item
            label="Bcc all emails to (optional)"
            name="mailBcc"
            className="routing-form-item"
            rules={[{ type: 'email', message: 'This field should be a valid email' }]}
          >
            <Input />
          </Form.Item>
          {company.postClosingTools ||
            (company.cqcTitleEnabled && (
              <Form.Item
                label="Participants receive an email when:"
                name="observerNotificationLevel"
                className="routing-form-item"
              >
                <Select>{renderOptions(companyOptions, 'observerNotificationLevel')}</Select>
              </Form.Item>
            ))}
        </Col>
      </Row>
      <Row align="bottom" gutter={24}>
        <Col span={24}>
          <h3 className="routing-subtitle">Billing</h3>
        </Col>
        <Col {...gridLayout}>
          <Form.Item
            label="Accounting email address"
            name="accountingEmail"
            className="routing-form-item"
            rules={FORM_RULES.standardCommaSeparatedEmails}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Send monthly invoices to"
            name="invoiceMail"
            className="routing-form-item"
            rules={FORM_RULES.standardCommaSeparatedEmails}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col {...gridLayout}>
          <Form.Item
            label="When a client responds to an invoice email, send the email to..."
            name="orderSendClientInvoiceFrom"
            className="routing-form-item"
          >
            <Select>{renderOptions(emailSettingsOptions, 'orderSendClientInvoiceFrom')}</Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="displayNotaryInfoOnClientInvoice"
            style={{ marginBottom: 4 }}
            className="routing-form-item"
            valuePropName="checked"
          >
            <Checkbox>{"Include the notary's name on the client invoice"}</Checkbox>
          </Form.Item>
          <Form.Item name="displayOrderAddressOnClientInvoice" className="routing-form-item" valuePropName="checked">
            <Checkbox>Include the order address on the client invoice</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <h3 className="routing-subtitle">Email reply behavior</h3>
        </Col>
        <Col {...gridLayout}>
          <Form.Item
            label="When a user replies to an email sent from Snapdocs, their message should go to..."
            name="emailsSentToUsersReplyTo"
            className="routing-form-item"
          >
            <Select>{renderOptions(emailSettingsOptions, 'emailsSentToUsersReplyTo')}</Select>
          </Form.Item>
          <Form.Item
            label="When a client, notary, consumer or participant replies to an order email, their message should go to..."
            name="orderClientOrNotaryReplyTo"
            className="routing-form-item"
          >
            <Select>{renderOptions(emailSettingsOptions, 'orderClientOrNotaryReplyTo')}</Select>
          </Form.Item>
        </Col>
      </Row>
      <Row className="save-form-button">
        <Col>
          <Form.Item className="routing-form-item">
            <Button loading={isLoading} type="primary" size="large" htmlType="submit">
              Save changes
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
