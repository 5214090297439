import { Icon, Icons } from '../Shared/Ant'
import { Form, Input, Select, Checkbox, Row, Col } from 'antd'
import React, { FC, ReactElement, useState } from 'react'
import { ScanbacksProps, ScanbackReturnChoiceName, ScanbackReturnChoiceDeadline } from './types'
import InputMask from 'react-input-mask'
import { formValidatorForPhone } from '../../utils/validatePhone'
import { formValidatorForArrayofThreeEmails } from '../../utils/validateEmail'
import { SCANBACK_RETURN_CHOICE_DEADLINES, SCANBACK_RETURN_CHOICES } from '../../constants/GLOBAL'

export const Scanbacks: FC<ScanbacksProps> = ({
  product,
  scanbackReturnChoices,
  setScanbackReturnChoices,
  setReturnDeadline,
  returnDeadline
}): ReactElement => {
  const preferredReturnChoice = scanbackReturnChoices.find((choice) => choice.preferred)
  const uploadReturnChoice = scanbackReturnChoices.find((choice) => choice.name === SCANBACK_RETURN_CHOICES.UPLOAD)
  const emailReturnChoice = scanbackReturnChoices.find((choice) => choice.name === SCANBACK_RETURN_CHOICES.EMAIL)
  const faxReturnChoice = scanbackReturnChoices.find((choice) => choice.name === SCANBACK_RETURN_CHOICES.FAX)
  const otherReturnChoice = scanbackReturnChoices.find((choice) => choice.name === SCANBACK_RETURN_CHOICES.OTHER)

  const { Option } = Select
  const [showAllowedReturnMethods, setShowAllowedReturnMethods] = useState(
    scanbackReturnChoices.some((choice) => choice.preferred === false)
  )

  const toggleUploadAllowed = () => {
    toggleAllowedReturnMethod(SCANBACK_RETURN_CHOICES.UPLOAD)
  }

  const toggleEmailAllowed = () => {
    toggleAllowedReturnMethod(SCANBACK_RETURN_CHOICES.EMAIL)
  }

  const toggleFaxAllowed = () => {
    toggleAllowedReturnMethod(SCANBACK_RETURN_CHOICES.FAX)
  }

  const toggleOtherReturnAllowed = () => {
    toggleAllowedReturnMethod(SCANBACK_RETURN_CHOICES.OTHER)
  }

  const toggleAllowedReturnMethod = (name: ScanbackReturnChoiceName) => {
    let updatedScanbackReturnChoices = [...scanbackReturnChoices]

    const oldReturnChoiceIndex = updatedScanbackReturnChoices.findIndex((choice) => choice.name === name)
    if (oldReturnChoiceIndex > -1) {
      updatedScanbackReturnChoices.splice(oldReturnChoiceIndex, 1)
    } else {
      const newReturnChoice = { id: -1, name: name, value: '', preferred: !preferredReturnChoice }
      updatedScanbackReturnChoices = updatedScanbackReturnChoices.concat([newReturnChoice])
    }

    setScanbackReturnChoices(updatedScanbackReturnChoices)
  }

  const updateChoiceValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, id } = event.currentTarget
    const updatedScanbackReturnChoices = [...scanbackReturnChoices]

    const updatedChoiceIndex = updatedScanbackReturnChoices.findIndex((choice) => choice.name === id)
    const updatedChoice = { ...updatedScanbackReturnChoices[updatedChoiceIndex], value: value }
    updatedScanbackReturnChoices[updatedChoiceIndex] = updatedChoice
    setScanbackReturnChoices(updatedScanbackReturnChoices)
  }

  const handleReturnDeadline = (value: ScanbackReturnChoiceDeadline) => {
    setReturnDeadline(value)
  }

  const handleShowAllowedReturnMethods = () => {
    setShowAllowedReturnMethods(true)
  }

  const handlePreferredReturnMethod = (name: ScanbackReturnChoiceName) => {
    if (preferredReturnChoice?.name === name) return

    let updatedScanbackReturnChoices = [...scanbackReturnChoices]

    // The dropdown was cleared, remove the preferred choice
    // If there is a non-preferred choice, make it the preferred choice
    if (name === undefined) {
      updatedScanbackReturnChoices = updatedScanbackReturnChoices.filter(
        (choice) => choice.name !== preferredReturnChoice?.name
      )
      if (updatedScanbackReturnChoices[0]) {
        updatedScanbackReturnChoices[0].preferred = true
      }
      setScanbackReturnChoices(updatedScanbackReturnChoices)
      return
    }

    // An option was selected without a previous option, add the new option
    if (!preferredReturnChoice) {
      const newPreferredReturnChoice = { id: -1, name: name, value: '', preferred: true }
      updatedScanbackReturnChoices = updatedScanbackReturnChoices.concat([newPreferredReturnChoice])
      setScanbackReturnChoices(updatedScanbackReturnChoices)
      return
    }

    // The dropdown was changed to a different option without the allowed methods shown, replace the choice
    if (!showAllowedReturnMethods) {
      const newPreferredReturnChoice = { id: -1, name: name, value: '', preferred: true }
      setScanbackReturnChoices([newPreferredReturnChoice])
      return
    }

    // The dropdown was changed to a different option with the allowed methods shown, switch which choice is preferred
    const oldPreferredReturnChoiceIndex = updatedScanbackReturnChoices.findIndex((choice) => choice.preferred)
    if (oldPreferredReturnChoiceIndex > -1) {
      const oldPreferredReturnChoice = {
        ...updatedScanbackReturnChoices[oldPreferredReturnChoiceIndex],
        preferred: false
      }
      updatedScanbackReturnChoices[oldPreferredReturnChoiceIndex] = oldPreferredReturnChoice
    }

    const newPreferredReturnChoiceIndex = updatedScanbackReturnChoices.findIndex((choice) => choice.name === name)
    if (newPreferredReturnChoiceIndex > -1) {
      const newPreferredReturnChoice = {
        ...updatedScanbackReturnChoices[newPreferredReturnChoiceIndex],
        preferred: true
      }
      updatedScanbackReturnChoices[newPreferredReturnChoiceIndex] = newPreferredReturnChoice
    } else if (name) {
      const newPreferredReturnChoice = { id: -1, name: name, value: '', preferred: true }
      updatedScanbackReturnChoices = updatedScanbackReturnChoices.concat([newPreferredReturnChoice])
    }

    setScanbackReturnChoices(updatedScanbackReturnChoices)
  }

  return (
    <>
      <Form.Item
        label="Scanback return deadline"
        name="scanbackReturnDeadline"
        placeholder="Tell the notary when you need scanbacks..."
      >
        <>
          <Select
            allowClear
            onChange={handleReturnDeadline}
            defaultValue={returnDeadline}
            data-test="scanback-return-deadline"
          >
            <Option value={SCANBACK_RETURN_CHOICE_DEADLINES.SAME_DAY}>ASAP - before same-day cutoff</Option>
            <Option value={SCANBACK_RETURN_CHOICE_DEADLINES.WITHIN_2_HOURS}>
              ASAP - within 2 hrs of appointment time
            </Option>
            <Option value={SCANBACK_RETURN_CHOICE_DEADLINES.WITHIN_3_HOURS}>
              ASAP - within 3 hrs of appointment time
            </Option>
            <Option value={SCANBACK_RETURN_CHOICE_DEADLINES.WITHIN_4_HOURS}>
              ASAP - within 4 hrs of appointment time
            </Option>
            <Option value={SCANBACK_RETURN_CHOICE_DEADLINES.WITHIN_5_HOURS}>
              ASAP - within 5 hrs of appointment time
            </Option>
            <Option value={SCANBACK_RETURN_CHOICE_DEADLINES.WITHIN_6_HOURS}>
              ASAP - within 6 hrs of appointment time
            </Option>
            <Option value={SCANBACK_RETURN_CHOICE_DEADLINES.NOT_SPECIFIED}>Not specified</Option>
          </Select>

          {returnDeadline === SCANBACK_RETURN_CHOICE_DEADLINES.WITHIN_2_HOURS && (
            <div className="turnaround-warning">
              <Icon component={Icons.Warning} />
              Due to the fast turnaround time, there may be fewer notaries able to accommodate this request.
            </div>
          )}
        </>
      </Form.Item>

      <Form.Item
        label="Preferred scanback return method"
        placeholder="Tell the notary how to return scanbacks..."
        className={`${!showAllowedReturnMethods ? 'mb-0' : ''}`}
      >
        <Select
          allowClear
          value={preferredReturnChoice?.name}
          onChange={handlePreferredReturnMethod}
          data-test="preferred-scanback-method"
        >
          <Option value={SCANBACK_RETURN_CHOICES.UPLOAD}>
            Upload to Snapdocs&nbsp;
            <span className="snapdocs-secure">(</span>
            <Icon className="snapdocs-secure" component={Icons.Lock} />
            <span className="snapdocs-secure">&nbsp;Secure &amp; easy access)</span>
          </Option>
          <Option value={SCANBACK_RETURN_CHOICES.EMAIL}>Email</Option>
          <Option value={SCANBACK_RETURN_CHOICES.FAX}>Fax</Option>
          <Option value={SCANBACK_RETURN_CHOICES.OTHER}>Other...</Option>
          <Option value={SCANBACK_RETURN_CHOICES.NOT_SPECIFIED}>Not specified</Option>
        </Select>
      </Form.Item>

      {emailReturnChoice?.preferred && (
        <Form.Item
          label="Email"
          name={SCANBACK_RETURN_CHOICES.EMAIL}
          initialValue={emailReturnChoice?.value}
          preserve={false}
          className={`${!showAllowedReturnMethods ? 'mb-0' : ''}`}
          rules={[
            {
              required: true,
              message:
                'Must be a valid email or list of no more than 3 valid emails separated by commas (e.g. sam@example.com, sara@example.com)',
              validator: formValidatorForArrayofThreeEmails
            }
          ]}
        >
          <Input placeholder="scans@example.com, scans@example.com" onChange={updateChoiceValue} />
        </Form.Item>
      )}

      {faxReturnChoice?.preferred && (
        <Form.Item
          label="Fax"
          name={SCANBACK_RETURN_CHOICES.FAX}
          initialValue={faxReturnChoice?.value}
          preserve={false}
          className={`${!showAllowedReturnMethods ? 'mb-0' : ''}`}
          rules={[
            {
              required: true,
              message: 'Fax number must be 10 characters.',
              validator: formValidatorForPhone
            }
          ]}
        >
          <InputMask mask="(999) 999-9999" onChange={updateChoiceValue} />
        </Form.Item>
      )}

      {otherReturnChoice?.preferred && (
        <Form.Item
          label="Other"
          name={SCANBACK_RETURN_CHOICES.OTHER}
          className={`${!showAllowedReturnMethods ? 'mb-0' : ''}`}
          initialValue={otherReturnChoice?.value}
          preserve={false}
          rules={[{ required: true }]}
        >
          <Input placeholder="Add other return method..." onChange={updateChoiceValue} />
        </Form.Item>
      )}

      {!showAllowedReturnMethods && (
        <Row>
          <Col className="mb-16 allow-other-return-methods" offset={8}>
            <a onClick={handleShowAllowedReturnMethods}>Allow other return methods?</a>
          </Col>
        </Row>
      )}

      {showAllowedReturnMethods && (
        <Form.Item label="Allowed return methods" placeholder="Add other return method...">
          <div className="allowed-input">
            <Checkbox
              checked={!!uploadReturnChoice}
              onClick={toggleUploadAllowed}
              disabled={uploadReturnChoice?.preferred}
              data-test="upload-return-method"
            />{' '}
            Upload to Snapdocs
            {uploadReturnChoice?.preferred && <span> (preferred)</span>}
          </div>

          <div className="allowed-input">
            <div>
              <Checkbox
                checked={!!emailReturnChoice}
                onClick={toggleEmailAllowed}
                disabled={emailReturnChoice?.preferred}
                data-test="email-return-method"
              />{' '}
              Email
              {emailReturnChoice?.preferred && <span> (preferred)</span>}
            </div>
            {!!emailReturnChoice && !emailReturnChoice.preferred && (
              <Form.Item
                noStyle
                label="Email"
                name={SCANBACK_RETURN_CHOICES.EMAIL}
                className="allowed-input"
                initialValue={emailReturnChoice?.value}
                preserve={false}
                rules={[
                  {
                    required: true,
                    message: 'Enter up to three comma separated emails',
                    validator: formValidatorForArrayofThreeEmails
                  }
                ]}
              >
                <Input placeholder="scans@example.com, scans@example.com" onChange={updateChoiceValue} />
              </Form.Item>
            )}
          </div>

          <div className="allowed-input">
            <div>
              <Checkbox
                checked={!!faxReturnChoice}
                onClick={toggleFaxAllowed}
                disabled={faxReturnChoice?.preferred}
                data-test="fax-return-method"
              />{' '}
              Fax
              {faxReturnChoice?.preferred && <span> (preferred)</span>}
            </div>
            {!!faxReturnChoice && !faxReturnChoice.preferred && (
              <Form.Item
                name={SCANBACK_RETURN_CHOICES.FAX}
                className="allowed-input"
                initialValue={faxReturnChoice?.value}
                preserve={false}
                rules={[
                  {
                    required: true,
                    message: 'Fax number must be 10 characters.',
                    validator: formValidatorForPhone
                  }
                ]}
              >
                <InputMask mask="(999) 999-9999" onChange={updateChoiceValue} />
              </Form.Item>
            )}
          </div>

          <div className="allowed-input">
            <Checkbox
              checked={!!otherReturnChoice}
              onClick={toggleOtherReturnAllowed}
              disabled={otherReturnChoice?.preferred}
              data-test="other-return-method"
            />{' '}
            Other
            {otherReturnChoice?.preferred && <span> (preferred)</span>}
          </div>
          {!!otherReturnChoice && !otherReturnChoice.preferred && (
            <Form.Item
              name={SCANBACK_RETURN_CHOICES.OTHER}
              className="allowed-input"
              initialValue={otherReturnChoice?.value}
              preserve={false}
              rules={[{ required: true }]}
            >
              <Input placeholder="Other" initialValue={otherReturnChoice?.value} onChange={updateChoiceValue} />
            </Form.Item>
          )}
        </Form.Item>
      )}

      {/* This form item should not be shown on the order create/edit page */}
      <Form.Item
        label="Additional scanback instructions for notary"
        name="scanbacksInstructions"
        initialValue={product.scanbacksInstructions || ''}
      >
        <Input.TextArea style={{ height: 120 }} />
      </Form.Item>
    </>
  )
}
