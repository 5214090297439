import React, { useEffect, FC, ReactElement } from 'react'
import { Provider } from 'react-redux'
import { Space, Typography, Button } from 'antd'
import { CheckCircleOutlined, ClockCircleOutlined } from '@ant-design/icons'
import ErrorsTable from './ErrorsTable'
import { Requests } from '../../../api'
import { QC_REPORTS } from '../../../constants/QC_REPORTS'
import { Snapdocs } from '../../../hooks/api'
import { ChannelEvents } from '../../../models/event/channelEvents'
import { store } from '../../../store'
import { TitleQCReportProps } from './types'
import { PusherWrapper } from '../../../utils/pusherProvider'

const DESCRIPTIONS = {
  IN_PROGRESS: 'Snapdocs has received scanbacks from the notary and is the process of reviewing those documents.',
  COMPLETE: 'Snapdocs has reviewed the signed documents and produced the below quality control report.',
  PASSED: 'No errors were detected.',
  ERRORS: {
    agent: 'The settlement company or signing service may reach out to resolve issues.',
    client: 'Please work with the notary to resolve any outstanding issues specified below.'
  }
}

const STATUS_ICONS = {
  [QC_REPORTS.STATUSES.IN_PROGRESS]: {
    icon: ClockCircleOutlined,
    color: 'color-ant-blue-4',
    label: 'Quality control check - in progress'
  },
  [QC_REPORTS.STATUSES.PASSED]: {
    icon: CheckCircleOutlined,
    color: 'color-ant-green-4',
    label: 'Quality control passed - no errors found'
  }
}

export const TitleQCReport: FC<TitleQCReportProps> = ({ orderId, role, shouldDisplayQCLink }): ReactElement | null => {
  const { response: qcReport } = Snapdocs.useResources(Requests.titleQCReport({ orderId }))
  const { completedAt, pusherConfig, reviewResponses, status, reviewUrl } = qcReport || {}

  useEffect(() => {
    if (!pusherConfig) return

    const { pusherAppKey, pusherAppCluster, qcReportsChannel } = pusherConfig
    const pusher = new PusherWrapper(pusherAppKey, pusherAppCluster)

    pusher.subscribeForEvent(qcReportsChannel, ChannelEvents.QcReportUpdated, () => {
      Snapdocs.fetchResources(Requests.titleQCReport({ orderId }), true)
    })

    return () => pusher.unsubscribeFromTheChannel(qcReportsChannel)
  }, [pusherConfig])

  const inProgress = status === QC_REPORTS.STATUSES.IN_PROGRESS
  const errors = status === QC_REPORTS.STATUSES.ERRORS

  let description
  switch (status) {
    case QC_REPORTS.STATUSES.IN_PROGRESS:
      description = DESCRIPTIONS.IN_PROGRESS
      break
    case QC_REPORTS.STATUSES.ERRORS:
      description = `${DESCRIPTIONS.COMPLETE} ${DESCRIPTIONS.ERRORS[role as keyof object] || ''}`
      break
    case QC_REPORTS.STATUSES.PASSED:
      description = `${DESCRIPTIONS.COMPLETE} ${DESCRIPTIONS.PASSED}`
      break
  }

  const statusIcon = STATUS_ICONS[status]
  const StatusIcon = statusIcon?.icon

  if (!inProgress && !completedAt) return null
  return (
    <div className="panel panel-default">
      <div className="panel-heading">
        <h3 className="panel-title">Quality Control</h3>
      </div>
      <div className="panel-body">
        <div style={{ marginBottom: 20 }}>
          <Typography.Text>{description}</Typography.Text>
        </div>
        {errors ? (
          <ErrorsTable errors={reviewResponses} />
        ) : (
          <Space direction="vertical" align="center" style={{ width: '100%' }}>
            <StatusIcon className={statusIcon.color} style={{ fontSize: 42 }} />
            <Typography.Text style={{ fontSize: 20 }}>{statusIcon.label}</Typography.Text>
            {shouldDisplayQCLink && reviewUrl && (
              <Button
                type="primary"
                href={reviewUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginTop: 16 }}
              >
                Start QC
              </Button>
            )}
          </Space>
        )}
      </div>
    </div>
  )
}

const WithProvider: FC<TitleQCReportProps> = (props): ReactElement => (
  <Provider store={store}>
    <TitleQCReport {...props} />
  </Provider>
)

export default WithProvider
