import { RuleConditionFields } from './types'

export const supportedOperators: { label: string; value: string }[] = [
  { label: 'is equal to', value: '==' },
  { label: 'is not equal to', value: '!=' },
  { label: 'is in', value: 'in' },
  { label: 'is not in', value: 'none' }
]

export const findFieldByValue = (value: string, fields: RuleConditionFields[]): RuleConditionFields | undefined => {
  return fields.find((field) => field.value === value)
}
