import React, { FC, useEffect, useState } from 'react'
import { Select, Alert } from 'antd'
import { RuleConditionFields, JsonLogicRule } from './types'
import './styles.modules.scss'
import { supportedOperators, findFieldByValue } from './utils'
import { extractRuleComponents, createRuleLogic } from './ruleLogicUtils'

interface RuleLogicEditorProps {
  ruleLogic: JsonLogicRule
  supportedRuleConditionFields: RuleConditionFields[]
  editMode: boolean
  onRuleChange: (updatedRuleLogic: JsonLogicRule, validationError?: string) => void
  setRuleName: (ruleName: string) => void
}

interface RuleComponents {
  field: string
  operator: string
  value: string | string[]
  displayValue: string
  validationError?: string
}

export const RuleLogicEditor: FC<RuleLogicEditorProps> = ({
  ruleLogic,
  supportedRuleConditionFields,
  editMode,
  onRuleChange,
  setRuleName
}) => {
  // Helper function to convert value to display string
  const getDisplayValue = (value: string | string[]): string => {
    if (Array.isArray(value)) {
      return value.join(', ')
    }
    return String(value)
  }

  // Initialize state with extracted components
  const [components, setComponents] = useState<RuleComponents>(() => {
    const { field, operator, value } = extractRuleComponents(ruleLogic)
    return {
      field,
      operator,
      value,
      displayValue: getDisplayValue(value)
    }
  })

  // Function to validate the input value based on operator
  const validateValue = (value: string, operator: string): string | undefined => {
    // For "==" and "!=" operators, don't allow comma-separated values
    if ((operator === '==' || operator === '!=') && value.includes(',')) {
      return 'Multiple values are not allowed for "is equal to" and "is not equal to" operators. Use "is in" or "is not in" for multiple values.'
    }

    // Don't allow special characters except commas for "in" and "none" operators
    const specialCharsRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|.<>\/?]+/
    if (specialCharsRegex.test(value)) {
      return 'Special characters are not allowed.'
    }

    // Only allow strings or numbers
    const validInputRegex = /^[a-zA-Z0-9,\s]*$/
    if (!validInputRegex.test(value)) {
      return 'Only alphanumeric characters are allowed.'
    }

    return undefined
  }

  // Convert empty strings to "Empty" for display
  const convertForDisplay = (values: string | string[]): string[] => {
    validateValue(String(values), components.operator)

    if (Array.isArray(values)) {
      return values.map((v) => (v === '' ? 'Empty' : v))
    } else if (values === '') {
      return ['Empty']
    } else if (typeof values === 'string') {
      return [values]
    }
    return []
  }

  // State to track selected values
  const [selectedValues, setSelectedValues] = useState<string[]>(() => {
    return convertForDisplay(components.value)
  })

  // Update state when ruleLogic changes
  useEffect(() => {
    const { field, operator, value } = extractRuleComponents(ruleLogic)
    const displayValue = getDisplayValue(value)
    const validationError = validateValue(displayValue, operator)

    setComponents({
      field,
      operator,
      value,
      displayValue,
      validationError
    })

    setSelectedValues(convertForDisplay(value))
  }, [ruleLogic])

  // Convert "Empty" to empty string for storage
  const convertForStorage = (values: string[]): string | string[] => {
    return values.map((v) => (v === 'Empty' ? '' : v))
  }

  // Handlers for field, operator, and value changes
  const handleFieldChange = (newField: string) => {
    const validationError = validateValue(String(selectedValues), components.operator)
    setComponents({
      ...components,
      field: newField,
      validationError
    })

    const valueForStorage = convertForStorage(selectedValues)
    const updatedRuleLogic = createRuleLogic(newField, components.operator, valueForStorage)

    onRuleChange(updatedRuleLogic)
    setRuleName(findFieldByValue(newField, supportedRuleConditionFields)?.name || '')
  }

  const handleOperatorChange = (newOperator: string) => {
    const validationError = validateValue(String(selectedValues), components.operator)
    setComponents({
      ...components,
      operator: newOperator,
      validationError
    })

    const valueForStorage = convertForStorage(selectedValues)
    const updatedRuleLogic = createRuleLogic(components.field, newOperator, valueForStorage)
    onRuleChange(updatedRuleLogic, validationError)
  }

  const handleValueChange = (newValues: string[]) => {
    setSelectedValues(newValues)
    const validationError = validateValue(String(newValues), components.operator)
    const valueForStorage = convertForStorage(newValues)
    const updatedRuleLogic = createRuleLogic(components.field, components.operator, valueForStorage)

    onRuleChange(updatedRuleLogic, validationError)
  }

  // Helper function to get the field name from its value
  const getFieldName = (fieldValue: string): string => {
    const field = findFieldByValue(fieldValue, supportedRuleConditionFields)
    return field ? field.name : fieldValue
  }

  // Helper function to format the rule as a sentence
  const formatRuleAsSentence = (): string => {
    const fieldName = getFieldName(components.field)

    // Format the display values, replacing "Empty" with "(empty)"
    const formattedValues = selectedValues.map((v) => (v === 'Empty' ? '(empty)' : v)).join(', ')

    // Format based on operator
    switch (components.operator) {
      case '==':
      case 'in':
        return `${fieldName} is ${formattedValues}`
      case '!=':
      case 'none':
        return `${fieldName} is not ${formattedValues}`
      default:
        return `${fieldName} ${components.operator} ${formattedValues}`
    }
  }

  return (
    <>
      {components.validationError && (
        <Alert description={components.validationError} type="error" showIcon style={{ marginBottom: '16px' }} />
      )}
      <div className="ruleLogicFieldsWrapper">
        {editMode ? (
          <>
            <Select
              placeholder="Select field"
              value={components.field}
              onChange={handleFieldChange}
              className="ruleField"
              disabled={!editMode}
            >
              {supportedRuleConditionFields.map((fieldOption) => (
                <Select.Option key={fieldOption.value} value={fieldOption.value}>
                  {fieldOption.name}
                </Select.Option>
              ))}
            </Select>

            <Select
              value={components.operator}
              onChange={handleOperatorChange}
              className="ruleField"
              disabled={!editMode}
            >
              {supportedOperators.map((op) => (
                <Select.Option key={op.value} value={op.value}>
                  {op.label}
                </Select.Option>
              ))}
            </Select>

            <Select
              placeholder="Enter value"
              value={selectedValues}
              onChange={handleValueChange}
              className="ruleField"
              disabled={!editMode}
              mode="tags"
              tokenSeparators={[',']}
            >
              <Select.Option key="empty" value="Empty">
                Empty
              </Select.Option>
            </Select>
          </>
        ) : (
          // View mode - show Rule as sentence
          <div className="ruleLogicText">{formatRuleAsSentence()}</div>
        )}
      </div>
    </>
  )
}
