export const RON = {
  NOTARY_STATE_RESTRICTIONS: {
    TITLE: 'Notary state restrictions',
    DESCRIPTION:
      'Orders for Indiana, Maryland, Montana, and New York will be restricted to notaries certified in that state.'
  },
  FORM: {
    PROVIDERS_LABEL: 'Providers',
    PROVIDERS_PLACEHOLDER: 'Select eligible providers',
    PROPERTY_STATE_LABEL: 'Property state',
    PROPERTY_STATE_PLACEHOLDER: 'Select property state',
    NOTARY_CERTIFICATION_STATES_LABEL: 'Notary certification state',
    NOTARY_CERTIFICATION_STATES_PLACEHOLDER: 'Select notary certification state',
    RESTRICTED_TOOLTIP: 'This state is already restricted.',
    DISABLED_TOOLTIP: 'Not recommended for RON at this time.',
    ADD_NOTARY_STATE_RESTRICTION_BUTTON: '+ Add notary state restriction'
  }
}
