import React, { FC, ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { Provider } from 'react-redux'
import { store } from '../../../store'
import { Alert, Button, Card, Divider, Row, Space, notification } from 'antd'
import { EligibilityToolSettingsParams, Rule, RuleSet, RuleConditionFields } from './types'
import '../../Shared/Ant/App.less'
import axios from 'axios'
import { PlusOutlined } from '@ant-design/icons'
import { RuleEditor } from './RuleEditor'
import './styles.modules.scss'

// API service functions
const apiService = {
  getHeaders: (csrfToken: string) => ({
    'Content-Type': 'application/vnd.api+json',
    Accept: 'application/vnd.api+json',
    'X-CSRF-Token': csrfToken
  }),

  fetchSettings: async (
    path: string,
    setError: (error: string | null) => void
  ): Promise<{ ruleSet: RuleSet | null; ruleFields: RuleConditionFields[] | null }> => {
    try {
      const response = await axios.get(path)
      if (response.data.errors && response.data.errors.count > 0) {
        setError('Failed to load eligibility settings')
        return { ruleSet: null, ruleFields: null }
      }

      setError(null)
      return {
        ruleSet: response.data.enote_eligibility_rule_set,
        ruleFields: response.data.rule_fields || []
      }
    } catch (error) {
      setError('Failed to load eligibility settings')
      return { ruleSet: null, ruleFields: null }
    }
  },

  saveRule: async (
    ruleToSave: Rule,
    path: string,
    ruleSetId: number,
    csrfToken: string,
    setError: (error: string | null) => void
  ): Promise<boolean> => {
    try {
      const rulePayload = {
        ...ruleToSave,
        logic: JSON.stringify(ruleToSave.logic)
      }

      const headers = apiService.getHeaders(csrfToken)
      const isNewRule = ruleToSave.id === 0
      const url = isNewRule ? `${path}/${ruleSetId}/rules` : `${path}/${ruleSetId}/rules/${ruleToSave.id}`

      let response

      if (isNewRule) {
        response = await axios.post(
          url,
          {
            data: {
              type: 'rules',
              attributes: rulePayload
            }
          },
          { headers }
        )
      } else {
        response = await axios.put(
          url,
          {
            data: {
              type: 'rules',
              id: ruleToSave.id,
              attributes: rulePayload
            }
          },
          { headers }
        )
      }

      if (response.data.errors && response.data.errors.count > 0) {
        setError('Failed to save rule')
        return false
      }

      setError(null)
      notification.success({
        message: `Rule ${isNewRule ? 'created' : 'updated'} successfully`,
        className: 'notification'
      })
      return true
    } catch (error) {
      setError('Failed to save rule')
      return false
    }
  },

  deleteRule: async (
    ruleId: number,
    path: string,
    ruleSetId: number,
    csrfToken: string,
    setError: (error: string | null) => void
  ): Promise<boolean> => {
    try {
      const deleteUrl = `${path}/${ruleSetId}/rules/${ruleId}`
      const response = await axios.delete(deleteUrl, {
        headers: apiService.getHeaders(csrfToken)
      })

      if (response.data.errors && response.data.errors.count > 0) {
        setError('Failed to delete rule')
        return false
      }

      setError(null)
      notification.success({ message: 'Rule deleted successfully', className: 'notification' })
      return true
    } catch (error) {
      setError('Failed to delete rule')
      return false
    }
  }
}

// Default empty rule
const DEFAULT_RULE: Rule = {
  id: 0,
  name: '',
  logic: { '==': [{ var: '' }, ''] },
  ineligibilityReason: '',
  isCustomIneligibilityReason: false
}

// RuleList component
const RuleList: FC<{
  rules: Rule[]
  onRuleChange: (rule: Rule) => Promise<boolean>
  onDeleteRule: (ruleId: number) => void
  supportedRuleConditionFields: RuleConditionFields[]
}> = React.memo(({ rules, onRuleChange, onDeleteRule, supportedRuleConditionFields }) => {
  return (
    <>
      {rules.map((rule, index) => (
        <React.Fragment key={rule.id || index}>
          <Row gutter={[16, 16]}>
            <Space className="ruleSpace">
              <RuleEditor
                rule={rule}
                onChange={onRuleChange}
                supportedRuleConditionFields={supportedRuleConditionFields}
                onDeleteRule={onDeleteRule}
                isEditMode={false}
              />
            </Space>
          </Row>
          <Divider />
        </React.Fragment>
      ))}
    </>
  )
})

// Main component
export const EligibilityToolSettings: FC<EligibilityToolSettingsParams> = ({
  eligibilityToolSettingsPath,
  csrfToken
}): ReactElement => {
  // State
  const [eNoteRuleSet, setENoteRuleSet] = useState<RuleSet>({ id: 0, name: '', rules: [] })
  const [ruleFields, setRuleFields] = useState<RuleConditionFields[]>([])
  const [addingRule, setAddingRule] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState<string | null>(null)

  // Load settings
  const loadSettings = useCallback(async () => {
    setIsLoading(true)
    try {
      const { ruleSet, ruleFields } = await apiService.fetchSettings(eligibilityToolSettingsPath, setError)
      if (ruleSet) {
        setENoteRuleSet(ruleSet)
      }
      if (ruleFields && ruleFields.length > 0) {
        setRuleFields(ruleFields)
      }
    } finally {
      setIsLoading(false)
    }
  }, [eligibilityToolSettingsPath, setError])

  // Initial load
  useEffect(() => {
    loadSettings()
  }, [loadSettings])

  // Handlers
  const handleRuleChange = useCallback(
    async (rule: Rule): Promise<boolean> => {
      if (isSubmitting) return false

      setIsSubmitting(true)
      let success = false
      try {
        success = await apiService.saveRule(rule, eligibilityToolSettingsPath, eNoteRuleSet.id, csrfToken, setError)
        // Only reload settings if the save was successful
        if (success) {
          await loadSettings()
        }
      } finally {
        setIsSubmitting(false)
      }

      // Return the success status to the caller
      return success
    },
    [eligibilityToolSettingsPath, eNoteRuleSet.id, csrfToken, isSubmitting, loadSettings, setError]
  )

  const handleDeleteRule = useCallback(
    async (ruleId: number) => {
      if (isSubmitting) return

      // Optimistically update UI
      setENoteRuleSet((prevState) => ({
        ...prevState,
        rules: prevState.rules.filter((rule) => rule.id !== ruleId)
      }))

      setIsSubmitting(true)
      try {
        await apiService.deleteRule(ruleId, eligibilityToolSettingsPath, eNoteRuleSet.id, csrfToken, setError)
        await loadSettings() // Refresh data to ensure consistency
      } finally {
        setIsSubmitting(false)
      }
    },
    [eligibilityToolSettingsPath, eNoteRuleSet.id, csrfToken, isSubmitting, loadSettings, setError]
  )

  const handleAddRule = useCallback(() => {
    setAddingRule(true)
  }, [])

  const handleSaveNewRule = useCallback(
    async (rule: Rule): Promise<boolean> => {
      if (isSubmitting) return false

      setIsSubmitting(true)
      let success = false
      try {
        success = await apiService.saveRule(rule, eligibilityToolSettingsPath, eNoteRuleSet.id, csrfToken, setError)
        if (success) {
          setAddingRule(false)
          await loadSettings()
        }
      } finally {
        setIsSubmitting(false)
      }

      // Return the success status to the caller
      return success
    },
    [eligibilityToolSettingsPath, eNoteRuleSet.id, csrfToken, isSubmitting, loadSettings, setError]
  )

  // Memoized components
  const addRuleButton = useMemo(
    () => (
      <Button
        type="default"
        icon={<PlusOutlined />}
        onClick={handleAddRule}
        disabled={isSubmitting || isLoading}
        style={{ fontSize: '16px', borderRadius: '6px' }}
      >
        Add rule
      </Button>
    ),
    [handleAddRule, isSubmitting, isLoading]
  )

  const newRuleEditor = useMemo(
    () =>
      addingRule && (
        <>
          <Row gutter={[16, 16]}>
            <Space className="ruleSpace">
              <RuleEditor
                rule={DEFAULT_RULE}
                onChange={handleSaveNewRule}
                supportedRuleConditionFields={ruleFields}
                onDeleteRule={() => setAddingRule(false)}
                isEditMode={true}
              />
            </Space>
          </Row>
          <Divider />
        </>
      ),
    [addingRule, handleSaveNewRule, ruleFields]
  )

  return (
    <Provider store={store}>
      <div className="eligibilityToolSettings">
        <h2>Eligibility Tool</h2>
        {error && (
          <Alert
            message="Error"
            description={error}
            type="error"
            showIcon
            closable
            onClose={() => setError(null)}
            style={{ marginBottom: '16px' }}
          />
        )}
        <Card loading={isLoading}>
          <h3>eNote Eligibility Rules</h3>
          <RuleList
            rules={eNoteRuleSet.rules}
            onRuleChange={handleRuleChange}
            onDeleteRule={handleDeleteRule}
            supportedRuleConditionFields={ruleFields}
          />
          {newRuleEditor}
          <Row>{addRuleButton}</Row>
        </Card>
      </div>
    </Provider>
  )
}
