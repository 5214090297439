import React from 'react'
import s from './styles.module.scss'

interface SignerDetailProps {
  icon?: string
  value?: string
  dataTestId?: string
}

export const SignerDetail: React.FC<SignerDetailProps> = ({ icon, value, dataTestId }) => {
  // TODO: Add tooltips to icons (they're added with jquery right now)
  const iconElement = icon ? <i className={icon} /> : null

  if (!value) {
    return null
  }

  return (
    <div className={s.signerDetail} data-testid={dataTestId}>
      {iconElement} {value}
    </div>
  )
}
