import React, { FC, ReactElement } from 'react'
import { Table } from 'antd'
import { ErrorsTableProps, ReviewResponse } from './types'

const ErrorsTable: FC<ErrorsTableProps> = ({ errors }): ReactElement => {
  const columns = [
    {
      title: 'Document',
      dataIndex: 'documentName'
    },
    {
      title: 'Page Number',
      dataIndex: 'pageNumber'
    },
    {
      title: 'Category',
      dataIndex: 'issues',
      render: (_: any, error: ReviewResponse) => error.issues.join(', ')
    },
    {
      title: 'Reviewer Comments',
      dataIndex: 'comments'
    }
  ]

  const getRowKey = (error: ReviewResponse) => `${error.documentName}${error.pageNumber}`

  return <Table columns={columns} dataSource={errors} pagination={false} rowKey={getRowKey} />
}

export default ErrorsTable
