import { Form, Input, InputNumber, Checkbox, Button, Switch, Tooltip, Divider, Select } from 'antd'
import React, { FC, ReactElement, useState } from 'react'
import { ProductFormBodyProps, StateSpecificPricingInterface, Product } from '../types'
import { AdvancedSettings } from './AdvancedSettings'
import { InputWithDescription } from './InputWithDescription'
import { DocumentChecklist } from './DocumentChecklist'
import { Scanbacks } from '../Scanbacks'
import { StateSpecificPricing } from './StateSpecificPricing'
import { ReactQuery } from '../../../api'
import { snakeCaseToCamelCase } from '../../../utils/snakeCaseToCamelCase'

const REVIEW_SLA_OPTIONS = [
  { value: 120, label: '2 hours' },
  { value: 150, label: '2 hours, 30 minutes' },
  { value: 180, label: '3 hours' },
  { value: 210, label: '3 hours, 30 minutes' },
  { value: 240, label: '4 hours (Default)' },
  { value: 270, label: '4 hours, 30 minutes' },
  { value: 300, label: '5 hours' },
  { value: 330, label: '5 hours, 30 minutes' },
  { value: 360, label: '6 hours' },
  { value: 390, label: '6 hours, 30 minutes' },
  { value: 420, label: '7 hours' },
  { value: 450, label: '7 hours, 30 minutes' },
  { value: 480, label: '8 hours' }
]

export const ProductFormBody: FC<ProductFormBodyProps> = ({
  allowStateSpecificPricing,
  companyId,
  product,
  submitBtnText,
  returnChoices,
  fromOrderForm,
  saveProduct,
  ronEnabled
}): ReactElement => {
  const [form] = Form.useForm()

  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false)
  const [showDocumentChecklist, setShowDocumentChecklist] = useState(product.documentChecklist.length > 0)
  const [showStateSpecificPricing, setShowStateSpecificPricing] = useState(
    Object.keys(product.stateSpecificPricing).length > 0
  )
  const [showExternalDocs, setShowExternalDocs] = useState(
    !!product.autosetDocStatus && product.autosetDocStatus !== 'none'
  )
  const [showSpecialInstructions, setShowSpecialInstructions] = useState(!!product.specialInstructions)
  const [scanbackReturnChoices, setScanbackReturnChoices] = useState(returnChoices)
  const [returnDeadline, setReturnDeadline] = useState(product.scanbackReturnDeadline)
  const [visibleForAllClients, setVisibleForAllClients] = useState(fromOrderForm)

  const parseFee = (fee: string | number): number => {
    const parsedFee = typeof fee === 'string' ? parseFloat(fee) : fee
    return parsedFee || 0
  }

  const scanbacksRequired = Form.useWatch('scanbacksRequired', form)
  const ronSigning = Form.useWatch('ronSigning', form) || false
  const witnessRequired = Form.useWatch('witnessRequired', form)
  const witnessCount = Form.useWatch('witnessCount', form)
  const qcEnabled = Form.useWatch('cqcTitleEnabled', form)
  const notaryFee = parseFee(Form.useWatch('vendorFee', form))
  const clientFee = parseFee(Form.useWatch('clientFee', form))
  const witnessFee = parseFee(Form.useWatch('witnessFee', form))

  const notaryFeeWithWitnessFee = (notaryFee + witnessFee * witnessCount).toFixed(2)
  const clientFeeWithWitnessFee = (clientFee + witnessFee * witnessCount).toFixed(2)

  const footerOffset = fromOrderForm ? 10 : 18

  const toggleShowAdvancedSettings = () => {
    setShowAdvancedSettings(!showAdvancedSettings)
  }

  const toggleShowStateSpecificPricing = () => {
    setShowStateSpecificPricing(!showStateSpecificPricing)
  }

  const toggleShowDocumentChecklist = () => {
    setShowDocumentChecklist(!showDocumentChecklist)
  }

  const toggleVisibleForAllClients = () => {
    setVisibleForAllClients(!visibleForAllClients)
  }

  const { company, companyLoading } = ReactQuery.useCompanyRequest(companyId)
  const { featurePolicies, featurePoliciesLoading } = ReactQuery.useFeaturePoliciesRequest(companyId)

  interface ProductWithStateSpecificArray extends Product {
    stateSpecificPricingArray: Array<{ state: string; clientFee: number; notaryFee: number }>
  }

  const onFinish = (values: ProductWithStateSpecificArray) => {
    values.scanbackReturnDeadline = returnDeadline
    values.scanbackReturnChoices = scanbackReturnChoices

    if (showStateSpecificPricing) {
      values.stateSpecificPricing = values.stateSpecificPricingArray.reduce((acc, obj) => {
        acc[obj.state] = { clientFee: obj.clientFee, notaryFee: obj.notaryFee }
        return acc
      }, {} as StateSpecificPricingInterface)
    }

    if (!witnessRequired || ronSigning) {
      values.witnessCount = null
      values.witnessFee = null
    }

    if (ronSigning) {
      values.witnessRequired = false
      values.attorneyRequired = false
    }

    if (!ronSigning || values.signerWitnessRequired === null) {
      values.signerWitnessRequired = false
    }

    if (!ronEnabled) {
      values.ronSigning = false
      values.signerWitnessRequired = false
    }

    if (!showDocumentChecklist) {
      values.documentChecklist = []
    }

    if (!showExternalDocs) {
      values.autosetDocStatus = 'none'
    }

    if (!showSpecialInstructions) {
      values.specialInstructions = ''
    }

    saveProduct(
      snakeCaseToCamelCase(values),
      showDocumentChecklist,
      showSpecialInstructions,
      showStateSpecificPricing,
      visibleForAllClients
    )
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  if (companyLoading || featurePoliciesLoading) {
    return <></>
  }

  const showVisibilityForAllClients = company.showVisibilityForAllClients && product.id === null && !fromOrderForm

  const stateSpecificPricingArray = Object.keys(product.stateSpecificPricing).reduce(
    (acc, key) =>
      acc.concat({
        state: key,
        notaryFee: product.stateSpecificPricing[key].notaryFee,
        clientFee: product.stateSpecificPricing[key].clientFee
      }),
    [] as Object[]
  )

  const initialValues = { ...product, stateSpecificPricingArray }
  if (initialValues.witnessCount === null) initialValues.witnessCount = 1
  if (initialValues.documentChecklist.length === 0) initialValues.documentChecklist = ['']
  if (initialValues.stateSpecificPricingArray.length === 0) initialValues.stateSpecificPricingArray = [{}]

  return (
    <Form
      id="addProductForm"
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={initialValues}
      colon={false}
      className="product-form-content"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Product name"
        name="productName"
        rules={[{ required: true, message: 'Please input your product name.' }]}
      >
        <Input />
      </Form.Item>

      {ronEnabled && (
        <Form.Item
          label={
            <Tooltip title="Remote Online Notarization">
              <span>RON?</span>
            </Tooltip>
          }
          name="ronSigning"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      )}

      {ronSigning && (
        <>
          <Form.Item
            label={
              <span>
                Require signer to
                <br />
                bring witness?
              </span>
            }
            name="signerWitnessRequired"
            valuePropName="checked"
          >
            <InputWithDescription InputType={Switch}>
              <span className="product-item-explanation">
                Witnesses must be co-located with the signer for RON closings.
              </span>
            </InputWithDescription>
          </Form.Item>
          <div style={{ marginBottom: '40px' }} />
        </>
      )}

      {featurePolicies.hybridClosing && (
        <Form.Item label="Hybrid signing" name="hybridSigning" valuePropName="checked">
          <InputWithDescription InputType={Switch}>
            <span className="product-item-explanation">Consumer has the option to e-sign non critical documents</span>
            <Tooltip title="This has the advantage of cheaper, faster notary appointment with fewer papers to print and sign.">
              <a href="#" className="product-item-tooltip">
                (?)
              </a>
            </Tooltip>
          </InputWithDescription>
        </Form.Item>
      )}

      {showVisibilityForAllClients && (
        <Form.Item label="Visible?" name="visibleForAllClients">
          <Switch defaultChecked={visibleForAllClients} onChange={toggleVisibleForAllClients} />
          <span className="product-item-explanation">Set product default visibility for all clients</span>
        </Form.Item>
      )}

      <Form.Item label="Document checklist?" name="documentChecklistCheckbox" valuePropName="checked">
        <InputWithDescription
          InputType={Switch}
          defaultChecked={showDocumentChecklist}
          checked={showDocumentChecklist}
          onChange={toggleShowDocumentChecklist}
        >
          <span className="product-item-explanation">Show document-related checklist to the notary</span>
        </InputWithDescription>
      </Form.Item>

      {showDocumentChecklist && <DocumentChecklist />}

      <Form.Item label="Require scanbacks?" name="scanbacksRequired" valuePropName="checked">
        <InputWithDescription InputType={Switch}>
          <span className="product-item-explanation">Require notary to upload scanbacks after appointment</span>
        </InputWithDescription>
      </Form.Item>

      {scanbacksRequired && (
        <Scanbacks
          product={product}
          scanbackReturnChoices={scanbackReturnChoices}
          setScanbackReturnChoices={setScanbackReturnChoices}
          returnDeadline={returnDeadline}
          setReturnDeadline={setReturnDeadline}
        />
      )}

      <Form.Item label="Require attorney?" name="attorneyRequired" valuePropName="checked">
        {ronSigning ? (
          <Tooltip title="Not yet supported for RON">
            <Switch disabled={ronSigning} data-test="attorney-required-toggle" />
          </Tooltip>
        ) : (
          <Switch data-test="attorney-required-toggle" />
        )}
      </Form.Item>

      {!ronSigning && (
        <>
          <Form.Item label="Require witness?" name="witnessRequired" valuePropName="checked">
            <Switch data-test="witness-required-toggle" />
          </Form.Item>

          {witnessRequired && (
            <Form.Item label="Number of witnesses" name="witnessCount">
              <Select
                data-test="witness-count-select"
                options={[
                  { value: '1', label: '1' },
                  { value: '2', label: '2' }
                ]}
              />
            </Form.Item>
          )}
        </>
      )}

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <a className="product-show-advanced" onClick={toggleShowAdvancedSettings}>
          {!showAdvancedSettings && 'Show advanced settings'}
          {showAdvancedSettings && 'Hide advanced settings'}
        </a>
      </Form.Item>

      {showAdvancedSettings && (
        <AdvancedSettings
          companyHasAugmentedClientExperience={company.hasAugmentedClientExperience}
          companyAutoStartAutomator={company.autoStartAutomator}
          showSpecialInstructions={showSpecialInstructions}
          setShowSpecialInstructions={setShowSpecialInstructions}
          showExternalDocs={showExternalDocs}
          setShowExternalDocs={setShowExternalDocs}
        />
      )}

      {featurePolicies.cqcTitleEnabled && (
        <>
          <Divider />
          <Form.Item name="cqcTitleEnabled" label="Quality Check" valuePropName="checked">
            <InputWithDescription InputType={Switch} data-test="cqc-title-switch">
              <span className="product-item-explanation">
                If enabled, scanbacks will automatically be reviewed by the Quality team within your specified review
                window.
              </span>
            </InputWithDescription>
          </Form.Item>
          {qcEnabled && (
            <Form.Item name="cqcTitleReviewSlaMinutes" label="Set your review window">
              <Select options={REVIEW_SLA_OPTIONS} />
            </Form.Item>
          )}
        </>
      )}

      <Divider />

      {witnessRequired && !ronSigning && (
        <Form.Item label="Witness fee" name="witnessFee" type="money">
          <InputWithDescription InputType={InputNumber} step="0.01" min={0} prefix="$">
            <span className="product-item-explanation">Per witness</span>
          </InputWithDescription>
        </Form.Item>
      )}

      <Form.Item label="Notary fee" name="vendorFee" className={company.showAdjustPrice ? 'mb-0' : ''} type="money">
        <InputWithDescription InputType={InputNumber} step="0.01" min={0} prefix="$">
          {witnessRequired && !ronSigning && (
            <span className="product-item-explanation">{`$${notaryFeeWithWitnessFee} with witness fee`}</span>
          )}
        </InputWithDescription>
      </Form.Item>

      {company.showAdjustPrice && (
        <Form.Item name="autoPriced" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
          <Checkbox className="product-form-label" data-test="auto-priced-checkbox">
            Adjust notary fee based on location
            <Tooltip title="Intelligently adjust notary fees for difficult locations based on local market rates. Never exceeds $10 more than your base fee.">
              <a href="#" className="product-item-tooltip">
                (?)
              </a>
            </Tooltip>
          </Checkbox>
        </Form.Item>
      )}

      {!featurePolicies.titleCompany && (
        <Form.Item label="Charge client" name="clientFee" type="money">
          <InputWithDescription InputType={InputNumber} step="0.01" min={0} prefix="$">
            {witnessRequired && !ronSigning && (
              <span className="product-item-explanation">{`$${clientFeeWithWitnessFee} with witness fee`}</span>
            )}
          </InputWithDescription>
        </Form.Item>
      )}

      {(company.hasAugmentedClientExperience || allowStateSpecificPricing) && (
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Switch
            checked={showStateSpecificPricing}
            onChange={toggleShowStateSpecificPricing}
            data-test="state-pricing-toggle"
          />
          <span className="product-item-explanation">Charge client differently per state</span>
        </Form.Item>
      )}

      {showStateSpecificPricing && (
        <StateSpecificPricing
          companyId={companyId}
          witnessRequired={witnessRequired && !ronSigning}
          totalWitnessFee={witnessFee * witnessCount}
        />
      )}

      <Form.Item name="submit" wrapperCol={{ offset: footerOffset, span: 16 }}>
        <Button type="primary" htmlType="submit" className="ant-btn-lg product-submit-btn">
          {submitBtnText}
        </Button>
      </Form.Item>
    </Form>
  )
}
