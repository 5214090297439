import React from 'react'
import { Icons } from '../../../../Shared/Ant'
import { Tooltip, Typography } from 'antd'
import './styles.modules.scss'
import { defaultSortOrder } from '../../../../../utils/table/defaultSortOrder'
import { Order } from '../../../../../models/order/order'
import { columnSortTooltip } from '../../../../../utils/table/columnSortTooltip'
import { SORT_TEXTS } from '../../../../../constants/COLUMN_SORTER'

export const NotaryLastNameColumn = (sortColumn: string, sortOrder: string) => {
  return {
    title: 'Notary',
    dataIndex: 'notaryLastName',
    defaultSortOrder: defaultSortOrder('notaryLastName', sortColumn, sortOrder),
    showSorterTooltip: columnSortTooltip({
      dataIndex: 'notaryLastName',
      sortColumn: sortColumn,
      sortOrder: sortOrder,
      ascText: SORT_TEXTS.CHAR_ASC,
      descText: SORT_TEXTS.CHAR_DESC,
      cancelText: SORT_TEXTS.CANCEL
    }),
    sorter: true,
    className: 'table-column notary-last-name-column',
    render: (notaryLastName: string | null, order: Order) => {
      return (
        <>
          {order.notaryName && (
            <>
              <div>
                {order.autoAssigned && (
                  <Tooltip title="Auto-assigned">
                    <Icons.Thunderbolt className="auto-assigned-icon" />
                  </Tooltip>
                )}
                {order.notaryVerified && (
                  <Tooltip title="Verified by Snapdocs">
                    <Icons.CheckCircle className="verified-icon" />
                  </Tooltip>
                )}
                <Typography.Text>{order.notaryName}</Typography.Text>
              </div>
              <div>
                {order.notaryEmailInvalidOrUnreachable && (
                  <div>
                    <Tooltip
                      className="notary-is-unreachable"
                      title="The last email sent to this person was not successfully delivered. Please contact them to double check their email address."
                    >
                      !
                    </Tooltip>
                  </div>
                )}
                {order.notaryMobilePhone && (
                  <Typography.Text type="secondary">{order.notaryMobilePhone}</Typography.Text>
                )}
                {!order.notaryMobilePhone && order.notaryWorkPhone && (
                  <Typography.Text type="secondary">{order.notaryWorkPhone}</Typography.Text>
                )}
                {!order.notaryMobilePhone && !order.notaryWorkPhone && order.notaryHomePhone && (
                  <Typography.Text type="secondary">{order.notaryHomePhone}</Typography.Text>
                )}
              </div>
            </>
          )}
        </>
      )
    }
  }
}
