import React from 'react'
import { Row, Col, Typography, Tooltip } from 'antd'
import ExclamationCircle from '../../../../../components/Shared/Icons/ExclamationCircle'
import { defaultSortOrder } from '../../../../../utils/table/defaultSortOrder'
import { columnSortTooltip } from '../../../../../utils/table/columnSortTooltip'
import { SORT_TEXTS } from '../../../../../constants/COLUMN_SORTER'
import './styles.modules.scss'

export const LABEL_STATUS = {
  NO_LABEL: 'no_label',
  LABEL_NOT_SENT: 'label_not_sent',
  LABEL_NOT_DOWNLOADED: 'label_not_downloaded',
  TRACKING_ADDED: 'tracking_added',
  LABEL_DOWNLOADED: 'label_downloaded',
  COURIER_DROPOFF: 'courier_dropoff',
  NOTARY_DROPOFF: 'notary_dropoff',
  ERROR_GENERATING_LABEL: 'error_generating_label'
}

export const LabelColumn = (sortColumn: string, sortOrder: string) => {
  const transformShippingLabel = (status: string | null) => {
    switch (status) {
      case LABEL_STATUS.NO_LABEL:
        return 'No'
      case LABEL_STATUS.LABEL_NOT_SENT:
        return 'No'
      case LABEL_STATUS.LABEL_NOT_DOWNLOADED:
        return 'Yes'
      case LABEL_STATUS.TRACKING_ADDED:
        return 'Yes'
      case LABEL_STATUS.LABEL_DOWNLOADED:
        return 'Yes'
      case LABEL_STATUS.ERROR_GENERATING_LABEL:
        return 'Error'
      default:
        return 'N/A'
    }
  }

  const getLabelColorClass = (status: string | null) => {
    switch (status) {
      case LABEL_STATUS.NO_LABEL:
        return 'color-ant-red-4'
      case LABEL_STATUS.LABEL_NOT_SENT:
        return 'color-ant-blue-4'
      case LABEL_STATUS.LABEL_NOT_DOWNLOADED:
        return 'color-ant-orange-4'
      case LABEL_STATUS.TRACKING_ADDED:
        return 'color-ant-purple-4'
      case LABEL_STATUS.LABEL_DOWNLOADED:
        return 'color-ant-green-4'
      case LABEL_STATUS.ERROR_GENERATING_LABEL:
        return 'color-ant-red-4'
      default:
        return 'color-ant-gray-8'
    }
  }

  const getTooltipText = (shippingLabel: string | null) => {
    switch (shippingLabel) {
      case LABEL_STATUS.NO_LABEL:
        return 'No label found'
      case LABEL_STATUS.LABEL_NOT_SENT:
        return 'Label has not been sent to notary'
      case LABEL_STATUS.LABEL_NOT_DOWNLOADED:
        return 'Notary has not downloaded label'
      case LABEL_STATUS.TRACKING_ADDED:
        return 'Tracking added'
      case LABEL_STATUS.LABEL_DOWNLOADED:
        return 'Notary downloaded label'
      case LABEL_STATUS.COURIER_DROPOFF:
        return 'Courier will drop off'
      case LABEL_STATUS.NOTARY_DROPOFF:
        return 'Notary will drop off'
      case LABEL_STATUS.ERROR_GENERATING_LABEL:
        return 'Error generating label'
      default:
        return null
    }
  }

  return {
    title: 'Label',
    dataIndex: 'shippingLabel',
    defaultSortOrder: defaultSortOrder('shippingLabel', sortColumn, sortOrder),
    showSorterTooltip: columnSortTooltip({
      dataIndex: 'shippingLabel',
      sortColumn: sortColumn,
      sortOrder: sortOrder,
      ascText: SORT_TEXTS.PRIORITY_ASC,
      descText: SORT_TEXTS.PRIORITY_DESC,
      cancelText: SORT_TEXTS.CANCEL
    }),
    sorter: true,
    className: 'table-column label-column',
    render: (shippingLabel: string | null) => {
      const labelText = transformShippingLabel(shippingLabel)
      const tooltipText = getTooltipText(shippingLabel)
      const labelColorClass = getLabelColorClass(shippingLabel)

      return (
        <Row>
          <Col span={24}>
            <Tooltip title={tooltipText}>
              {shippingLabel === LABEL_STATUS.ERROR_GENERATING_LABEL ? (
                <Typography.Text className={labelColorClass} data-test="error-label">
                  <ExclamationCircle className="exclamation-icon" />
                </Typography.Text>
              ) : labelText === 'N/A' ? (
                <Typography.Text type="secondary">{labelText}</Typography.Text>
              ) : (
                <Typography.Text className={labelColorClass}>{labelText}</Typography.Text>
              )}
            </Tooltip>
          </Col>
        </Row>
      )
    }
  }
}
