import React, { FC, ReactElement } from 'react'
import { RonForm } from './Ron/RonForm'
import { ClientFormProps } from './types'
import '../Shared/Ant/App.less'

export const ClientForm: FC<ClientFormProps> = ({
  states,
  disabledStates,
  restrictedStates,
  providers,
  selectedProviders,
  selectedRestrictions
}): ReactElement => {
  return (
    <RonForm
      states={states}
      disabledStates={disabledStates}
      restrictedStates={restrictedStates}
      providers={providers}
      selectedProviders={selectedProviders}
      selectedRestrictions={selectedRestrictions}
    />
  )
}
