import React, { FC, ReactElement } from 'react'
import { TrackingInfoProps } from '../types'
import { Button, Typography } from 'antd'
import OrderHelper from '../../../../helpers/OrderHelper'

export const TrackingInfo: FC<TrackingInfoProps> = ({ order }): ReactElement => {
  const trackingLink = (shippingMethod: string | null, trackingNumber: string | null) => {
    if (OrderHelper.isShippingMethodFedEx(order)) {
      return `https://www.fedex.com/fedextrack/?trknbr=${trackingNumber}`
    } else if (OrderHelper.isShippingMethodUPS(order)) {
      return `https://wwwapps.ups.com/WebTracking/processInputRequest?sort_by=status&tracknums_displayed=1&TypeOfInquiryNumber=T&loc=en_us&InquiryNumber1=${trackingNumber}&track.x=0&track.y=0`
    }
    return ''
  }

  const onTrack = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    window.open(trackingLink(order.shippingMethod, order.trackingNumber), '_blank')!.focus()
  }

  return (
    <>
      {!OrderHelper.isOpen(order) &&
        !OrderHelper.isCanceled(order) &&
        order.trackingNumber &&
        order.shippingMethod &&
        (order.trackingNumber === '' ||
          (order.shippingMethod === '' && <Typography.Text>No tr #</Typography.Text>) ||
          (trackingLink(order.shippingMethod, order.trackingNumber) !== '' && (
            <Button type="primary" size="small" onClick={onTrack}>
              Track
            </Button>
          )))}
    </>
  )
}
