import React, { FC, useState } from 'react'
import { Button, Input } from 'antd'
import './styles.modules.scss'

const { TextArea } = Input

interface IneligibilityReasonProps {
  ineligibilityReason: string
  customIneligibilityReason: string
  isCustomized: boolean
  setCustomIneligibilityReason: (ineligibilityReason: string) => void
  setEditMode: (editMode: boolean) => void
  editMode: boolean
  setIsReasonCustomized: (isReasonCustomized: boolean) => void
}

export const IneligibilityReason: FC<IneligibilityReasonProps> = ({
  ineligibilityReason,
  customIneligibilityReason,
  isCustomized,
  setCustomIneligibilityReason,
  setEditMode,
  editMode,
  setIsReasonCustomized
}) => {
  const [isCustomizedReasonClicked, setIsCustomizedReasonClicked] = useState(false)

  const onChangeCustomIneligibilityReason = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomIneligibilityReason(e.target.value)
    if (e.target.value) {
      setIsReasonCustomized(true)
    }
  }

  const onClickCustomizeButton = () => {
    setIsCustomizedReasonClicked(false)
    setEditMode(true)
  }

  return (
    <div className={'inEligibilityReasonComponent'}>
      {isCustomized && (
        <>
          <div className="customReasonHeader">Custom reason for ineligibility</div>
          {!editMode && <div className="inEligibilityReasonText">{ineligibilityReason}</div>}
        </>
      )}

      {!isCustomized && !isCustomizedReasonClicked && !editMode && (
        <Button type="link" size="small" className="customizeButton" onClick={onClickCustomizeButton}>
          Customize reason for ineligibility
        </Button>
      )}

      <div>
        {editMode && (
          <>
            <TextArea
              placeholder="Enter custom ineligibility reason"
              value={customIneligibilityReason}
              onChange={onChangeCustomIneligibilityReason}
              className="ruleField"
              style={{ width: '100%' }}
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
            {!isCustomized && ineligibilityReason && (
              <div>This will replace the following reason: {ineligibilityReason}</div>
            )}
          </>
        )}
      </div>
    </div>
  )
}
