import React, { FC, ReactElement } from 'react'
import { SettingsBlock } from './SettingsBlock'
import { Setting, SettingsT } from './types'
import { match } from './utils'

const settings: Setting[] = [
  {
    key: 'orderNotaryMarksSigningDidNotSign',
    title: 'Notary marks an order as “Did not sign”',
    description: '',
    type: 'checkbox',
    values: [],
    options: [],
    previewImage: 'notary_completion_status_did_not_sign.png',
    dependencies: [],
    notificationSettingKeys: ['notaryChangedToDidNotSign']
  },
  {
    key: '',
    title: 'The signing was canceled by Title or Lender',
    description: '',
    type: '',
    values: [],
    options: [],
    previewImage: '',
    dependencies: ['receives_orders_from_closings'],
    notificationSettingKeys: ['lenderOrTitleCanceledOrder']
  },
  {
    key: 'orderAdminSetsStatusAsIncomplete',
    title: 'I mark an order as “Canceled” or “On hold”',
    description: '',
    type: 'checkbox',
    values: [],
    options: [],
    previewImage: 'admin_canceled_onhold.png',
    dependencies: []
  },
  {
    key: 'orderNotaryMarksSigningComplete',
    title: 'Notary marks an order as “Complete”',
    description: '',
    type: 'checkbox',
    values: [],
    options: [],
    previewImage: 'notary_completion_status_completed.png',
    dependencies: []
  },
  {
    key: 'completionTimeOut',
    title: 'A notary forgets to add completion status, send a reminder in',
    description: '',
    type: 'radio',
    values: [],
    options: [],
    previewImage: '',
    dependencies: []
  },
  {
    key: 'orderQcFinished',
    title: 'QC completion results',
    description: '',
    type: 'checkbox',
    values: [],
    options: [],
    previewImage: '',
    dependencies: ['cqc_title_enabled']
  },
  {
    key: 'orderCompletionStatusAddedPostClosingReminder',
    title: 'A completed order has scanbacks, send a post-closing reminder',
    description: '',
    type: 'checkbox',
    values: [],
    options: [],
    previewImage: 'post_closing_reminder.png',
    dependencies: ['post_closing_tools']
  },
  {
    key: 'orderClosedWithoutSendingInvoice',
    title: 'Order is closed without sending an invoice, send completion notification',
    description: '',
    type: 'checkbox',
    values: [],
    options: [],
    previewImage: 'order_closed_no_invoice.png',
    dependencies: []
  },
  {
    key: 'orderCompletedSendThankYouEmail',
    title: 'Order is completed, send “Thank you” email to the notary',
    description:
      "We recommend leaving this option on. In addition to notifying the notary that the order is complete, this will check the billing address for the notary and request a W9 if you don't already have one on file.",
    type: 'checkbox',
    values: [],
    options: [],
    previewImage: 'notary_thank_you.png',
    dependencies: []
  }
]

export const OrderCompletionSettings: FC<SettingsT> = ({
  emailSettings,
  options,
  featurePolicies,
  notificationSettings,
  confirmBrowserExit
}): ReactElement => {
  return (
    <SettingsBlock
      id={emailSettings.id}
      companyId={emailSettings.companyId}
      title={'Order completion and status changes'}
      settings={match(settings, emailSettings, options)}
      featurePolicies={featurePolicies}
      notificationSettings={notificationSettings}
      confirmBrowserExit={confirmBrowserExit}
    />
  )
}
