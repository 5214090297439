import React, { FC, ReactElement } from 'react'
import { OrdersTable } from './OrdersTable'
import { DashboardContentProps } from './types'
import { DashboardHeader } from './DashboardHeader/DashBoardHeader'

export const DashboardContent: FC<DashboardContentProps> = ({
  transactionTypeFlipper,
  companyId,
  searchParams,
  changeSearchParams,
  pusherWrapper,
  isRealTimeDashboardEnabled,
  isSweepingEnabled,
  isRootCompany
}): ReactElement => {
  return (
    <>
      <DashboardHeader
        companyId={companyId}
        searchParams={searchParams}
        changeSearchParams={changeSearchParams}
        isRootCompany={isRootCompany}
      />
      <div className="orders-table" data-testid="orders-table">
        <OrdersTable
          transactionTypeFlipper={transactionTypeFlipper}
          companyId={companyId}
          searchParams={searchParams}
          changeSearchParams={changeSearchParams}
          pusherWrapper={pusherWrapper}
          isRealTimeDashboardEnabled={isRealTimeDashboardEnabled}
          isSweepingEnabled={isSweepingEnabled}
        />
      </div>
    </>
  )
}
